<template>
  <div class='main'>
    <div class="title">
      <h2>企业信息</h2>
      <div @click.stop="isEdit = true"
           class="edit"
           v-show="!isEdit">
        <img alt=""
             src="../../assets/common/company_icon_edit.png">
        <span>编辑</span>
      </div>
    </div>
    <div class="form">
      <ul>
        <li>
          <span>企业logo：</span>
          <UploadComponent :disabled="!isEdit"
                           @uploadSuccess="uploadLogo"
                           uploadpath="/company/logo/">
            <img :src="entLogo?entLogo:require('../../assets/common/ic_uploadlogo.png')"
                 v-show="!isEdit">
            <img :src="entLogoEdit?entLogoEdit:require('../../assets/common/ic_uploadlogo.png')"
                 v-show="isEdit">
          </UploadComponent>
        </li>
        <li>
          <span>企业名称：</span>
          <span v-show="!isEdit">{{entName}}</span>
          <Input placeholder="请输入企业名称"
                 v-model="entNameEdit"
                 v-show="isEdit"></Input>
        </li>
        <li>
          <span>企业简称：</span>
          <span v-show="!isEdit">{{entShortName}}</span>
          <Input placeholder="请输入企业简称"
                 v-model="entShortNameEdit"
                 v-show="isEdit"></Input>
        </li>
        <li>
          <span>企业法人：</span>
          <span v-show="!isEdit">{{entLinkman}}</span>
          <Input placeholder="请输入企业联系人"
                 v-model="entLinkmanEdit"
                 v-show="isEdit"></Input>
        </li>
        <li>
          <span>法人电话：</span>
          <span v-show="!isEdit">{{entPhone}}</span>
          <Input placeholder="请输入联系人电话"
                 v-model="entPhoneEdit"
                 v-show="isEdit"></Input>
        </li>
      </ul>
      <div class="editBtn"
           v-show="isEdit">
        <Button @click.stop="onSave"
                class="m-r-20"
                type="primary">确定
        </Button>
        <Button @click.stop="onCancle">取消</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {editEntDetail, getEntDetail} from '../../services/enterpriseapi'
import UploadComponent from '../../components/common/UploadComponent'

export default {
  components: {UploadComponent},
  data () {
    return {
      isEdit: false,
      entLogo: '',
      entLogoEdit: '',
      entName: '',
      entNameEdit: '',
      entShortName: '',
      entShortNameEdit: '',
      entLinkman: '',
      entLinkmanEdit: '',
      entPhone: '',
      entPhoneEdit: '',
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getEntDetail()
    },
    getEntDetail () {
      getEntDetail().then(res => {
        if (res.code === 0) {
          let data = res.data
          this.entLogo = this.entLogoEdit = data.logoUrl || ''
          this.entName = this.entNameEdit = data.name || ''
          this.entShortName = this.entShortNameEdit = data.shortName || ''
          this.entLinkman = this.entLinkmanEdit = data.legalName || ''
          this.entPhone = this.entPhoneEdit = data.legalPhone || ''
        }
      })
    },
    uploadLogo (pathArr) {
      this.entLogoEdit = pathArr[0]
    },
    onCancle () {
      this.isEdit = false
      this.entLogoEdit = this.entLogo
      this.entNameEdit = this.entName
      this.entShortNameEdit = this.entShortName
      this.entLinkmanEdit = this.entLinkman
      this.entPhoneEdit = this.entPhone
    },
    onSave () {
      let params = {
        logoUrl: this.entLogoEdit,
        name: this.entNameEdit,
        shortName: this.entShortNameEdit,
        legalName: this.entLinkmanEdit,
        legalPhone: this.entPhoneEdit
      }
      editEntDetail(params).then(res => {
        if (res.code === 0) {
          this.isEdit = false
          this.getEntDetail()
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
  .main {
    .title {
      height: 70px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d8d8d8;

      h2 {
        font-size: 20px;
      }

      .edit {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          line-height: 1;
          color: #2d8cf0;
        }
      }
    }

    .form {
      padding-left: 30px;
      margin-top: 20px;

      li {
        display: flex;
        margin-top: 30px;

        span {
          font-size: 16px;
          line-height: 32px;

          &:first-child {
            width: 96px;
          }
        }

        img {
          width: 240px;
          height: 136px;
          cursor: pointer;
        }

        ::v-deep .ivu-input-wrapper {
          width: 360px;
        }
      }

      .editBtn {
        margin-top: 30px;
        padding-left: 96px;

        ::v-deep .ivu-btn {
          width: 80px;
        }
      }
    }
  }
</style>
